<template>
  <v-dialog v-model="model" width="400" persistent @click:outside="closeDialog">
      <div class="dialog_wrapper">
          <p>{{text}}</p>
          <div class="actions">
              <v-btn
                width="160"
                height="44"
                color="primary"
                outlined
                @click="closeDialog"
            >
                Отмена
            </v-btn>
              <v-btn
                width="160"
                height="44"
                class="ml-3"
                color="error"
                outlined
                @click="onClick"
            >
                Удалить
            </v-btn>
          </div>
      </div>
  </v-dialog>
</template>

<script>
export default {
    props: {
        text: String,
        model: Boolean
    },
    methods: {
        onClick() {
            this.$emit('onClick')
        },
        closeDialog() {
            this.$emit('closeDialog')
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog_wrapper {
    padding: 30px 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        color: #021011;
    }
    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>