<template>
    <div>
        <div class="tour_navigation">
            <div>
                <v-select
                    label="Экскурсии"
                    :items='excursions'
                    height="48"
                    outlined
                    hide-details
                    dense
                    item-value="id"
                    item-text="name"
                    v-model="main_tour_id"
                >
                </v-select>
            </div>
            <v-btn
                color="primary"
                height="48"
                class="px-5 ml-5"
                @click="getList"
            >
                Поиск
            </v-btn>
        </div>
        <v-data-table
            :headers="headers"
            :items="tourRoutes.results"
            :loading='loading'
            disable-sort
            :options.sync="options"
            :server-items-length="tourRoutes.count"
        >
            <template v-slot:[`item.bus.bus_type`]="{ item }">
                {{item.bus.bus_type=='sitting' ? 'Сидячий' : 'Спальный'}}
            </template>
            <template v-slot:[`item.from_date`]="{ item }">
                {{$moment(item.from_date*1000).format("DD MMM YYYY HH:mm")}}
            </template>
            <template v-slot:[`item.to_date`]="{ item }">
                {{$moment(item.to_date*1000).format("DD MMM YYYY HH:mm")}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="table_actions">
                    <v-btn @click="onCopy(item)" icon>
                        <v-icon size="20">mdi-content-copy</v-icon>
                    </v-btn>
                    <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)'/>
                </div>
            </template>
        </v-data-table>
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' :hideButton='true'>
            <v-tabs-items v-model="tab" class="pa-1">
                <v-tab-item>
                    <v-form
                        ref="first_form"
                        lazy-validation
                    >
                        <input-content
                            :items="inputItems"
                            :model="model"
                            :dialog='dialog'
                        />
                    </v-form>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4"
                        @click="nextStep"
                    >
                        Далее
                    </v-btn>
                </v-tab-item>
                <v-tab-item>
                    <input-content
                        :items="descriptionItems"
                        :model="model"
                    />
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4 mr-2"
                        @click="tab = 0"
                    >
                        Назад
                    </v-btn>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4"
                        @click="onSubmit"
                    >
                        Сохранить
                    </v-btn>
                </v-tab-item>
            </v-tabs-items>
        </vue-dialog>
        <vue-delete-dialog :model='deleteDialog' @onClick='deleteHandler' @closeDialog='closeDelete' :text='"Вы уверены что хотите удалить маршрут?"'/>
    </div>
</template>

<script>
import VueDialog from '@/components/ui/vueDialog.vue'
import VueTableActions from '@/components/ui/vueTableActions.vue'
import vueDeleteDialog from '@/components/ui/vueDeleteDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import {mapActions, mapGetters} from 'vuex'
import {toursService} from '@/services/tours.js'
import pagination from "@/utils/pagination"
export default {
    mixins: [pagination],
    components: {
        VueDialog,
        InputContent,
        vueDeleteDialog,
        VueTableActions
    },
    data: () => ({
        loading: false,
        deleteDialog: false,
        tab: 0,
        main_tour_id: null,
        deleteId: null,
        descriptionItems: [
            {
                type: 'editor',
                empty: true,
                height: 200,
                label: 'Что входит в стоимость тура',
                model: 'included_in_tour_price'
            },
            {
                type: 'editor',
                empty: true,
                height: 200,
                label: 'Что нужно взять взять с собой',
                model: 'take_with_you'
            },
            {
                type: 'editor',
                empty: true,
                height: 200,
                label: 'Маршрут',
                model: 'route'
            },
            {
                type: 'editor',
                empty: true,
                height: 200,
                label: 'Условия',
                model: 'conditions'
            },
        ],
        inputItems: [
            {
                type: 'select',
                label: 'Откуда',
                items: [],
                model: 'from_location',
                getItems: 'getTourLocations',
                rules: 'required',
                cols: 6
            },
            {
                type: 'select',
                label: 'Куда',
                items: [],
                model: 'to_location',
                getItems: 'getTours',
                rules: 'required',
                cols: 6
            },
            {
                type: 'date',
                label: 'Даты отправления',
                model: 'tourdates',
                multiple: true,
                rules: 'required',
                cols: 6
            },
            {
                type: 'select',
                label: 'Номер автобуса',
                model: 'bus',
                multiple: false,
                getItems: 'getBuses',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'time',
                label: 'Время отправления',
                model: 'time',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Время',
                model: 'hours',
                rules: 'required',
                suffix: 'ЧАС',
                cols: 3
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Время',
                model: 'mins',
                rules: 'required',
                suffix: 'МИН',
                cols: 3
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость взрослого',
                model: 'adult_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость детского',
                model: 'child_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость пенсионера',
                model: 'pensioner_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Возраст ребёнка',
                model: 'child_age',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Возраст пенсионера',
                model: 'pensioner_age',
                rules: 'required',
                cols: 6
            },
        ],
        model: {},
        dialog: false,
        dialogOptions: {
            width: '700',
            title: 'Маршрут',
        },
        excursions: [],
        headers: [
            { text: 'Автобус', value: 'bus.number' },
            { text: 'Тип', value: 'bus.bus_type' },
            { text: 'Откуда', value: 'from_location.name' },
            { text: 'Тур', value: 'to_location.name' },
            { text: 'Отправление', value: 'from_date' },
            { text: 'Прибытие', value: 'to_date' },
            { text: 'Взрослый', value: 'adult_ticket' },
            { text: 'Детский', value: 'child_ticket' },
            { text: 'Пенсионер', value: 'pensioner_ticket' },
            { text: '', value: 'actions' },
        ],
        items: [],
    }),
    computed: {
        ...mapGetters({
            tourRoutes: 'tours/getTourRoutes'
        })
    },
    watch: {
        dialog(){
            if(!this.dialog) location.reload()
        }
    },
    created(){
        this.getExcursions()
    },
    methods: {
        ...mapActions({
            createTourRoute: 'tours/createTourRoute',
            getTourRoutes: 'tours/getTourRoutes'
        }),
        async getExcursions(){
            this.excursions = await toursService.getToursFilter() 
        },
        async getList() {
            this.loading = true
            let params = {
                    page: this.options.page, 
                    size: this.options.itemsPerPage,
                    main_tour_id: this.main_tour_id
                }
            await this.getTourRoutes(params)
            this.loading = false
        },
        nextStep() {
            if (!this.$refs.first_form.validate()) return
            this.tab = 1
        },
        closeDialog() {
            if (this.$refs.first_form) {
                this.$refs.first_form.reset()
            }
            this.dialog = false
            this.model = {}
        },
        onAdd() {
            this.inputItems.forEach(el => {
                if (el.model == 'tourdates') {
                    el.multiple = true
                }
            })
            this.tab = 0
            this.model = {}
            this.dialog = true
        },
        onCopy(item){
            this.tab = 0
            this.model = {}
            this.model = this.$copy(item)
            this.model.is_copy = true
            this.inputItems.forEach(el => {
                if (el.model == 'tourdates') {
                    el.multiple = true
                }
            })
            let h = Math.floor(item.travel_time % (3600*24) / 3600)
            let m = Math.floor(item.travel_time % 3600 / 60)
            // this.model.tourdates = this.$moment(item.from_date*1000).format('YYYY-MM-DD')
            this.model.time = this.$moment(item.from_date*1000).format('HH:mm')
            this.model.hours = `${h<9?'0'+h:h}`
            this.model.mins = `${m<9?'0'+m:m}`
            this.dialog = true
        },
        onEdit(item) {
            this.tab = 0
            this.model = {}
            this.model = this.$copy(item)
            this.inputItems.forEach(el => {
                if (el.model == 'tourdates') {
                    el.multiple = false
                }
            })
            let h = Math.floor(item.travel_time % (3600*24) / 3600)
            let m = Math.floor(item.travel_time % 3600 / 60)
            this.model.tourdates = this.$moment(item.from_date*1000).format('YYYY-MM-DD')
            this.model.time = this.$moment(item.from_date*1000).format('HH:mm')
            this.model.hours = `${h<9?'0'+h:h}`
            this.model.mins = `${m<9?'0'+m:m}`
            this.dialog = true
        },
        closeDelete() {
            this.deleteDialog = false
            this.deleteId = null
        },
        onDelete(item) {
            this.deleteId = item.id
            this.deleteDialog = true
        },
        async deleteHandler() {
            try {
                this.$loading(true)
                await toursService.deleteTourRoute(this.deleteId)
                await this.getList()
            } catch (e) {
                console.error(e);
            } finally {
                this.closeDelete()
                this.$loading(false)
            }
        },
        async onSubmit() {
            if(!this.model.included_in_tour_price) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Напишите, что должно входить в стоимость тура!",
                    color: "error",
                });
                return;
            }
            if(!this.model.take_with_you) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Напишите, что нужно взять с собой!",
                    color: "error",
                });
                return;
            }
            if(!this.model.route) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Напишите маршрут!",
                    color: "error",
                });
                return;
            }
            if(!this.model.conditions) {
                this.$store.commit("ui/SET_NOTIFICATION", {
                    show: true,
                    message: "Напишите условия!",
                    color: "error",
                });
                return;
            }
            try {
                this.$loading(true)
                let dates = []
                if (!this.model.time.split(':')) return
                if (this.model.time.split(':').length != 2) return 
                this.model.travel_time = ((this.model.hours * 60) * 60) + (this.model.mins * 60);

                if (this.model.id) {
                    this.model.bus = this.model.bus.id ?? this.model.bus
                    this.model.from_location = this.model.from_location.id
                    this.model.to_location = this.model.to_location.id
                    this.model.from_date = this.$moment(this.model.tourdates)
                                                .add(this.model.time.split(':')[0], 'hour')
                                                .add(this.model.time.split(':')[1], 'minutes')
                                                .locale("en")
                                                .valueOf() / 1000
                    if(this.model.is_copy) {
                        delete this.model.id
                        this.model.tourdates.forEach(element => {
                        let date = 
                        this.$moment(element)
                            .add(this.model.time.split(':')[0], 'hour')
                            .add(this.model.time.split(':')[1], 'minutes')
                            .locale("en")
                            .valueOf()
                            dates.push(date/1000)
                        });
                        this.model.dates = dates
                        await toursService.createTourRoute(this.model)
                    }
                    else await toursService.patchTourRoute(this.model)
                } else {
                    this.model.tourdates.forEach(element => {
                    let date = 
                    this.$moment(element)
                        .add(this.model.time.split(':')[0], 'hour')
                        .add(this.model.time.split(':')[1], 'minutes')
                        .locale("en")
                        .valueOf()
                        dates.push(date/1000)
                    });
                    this.model.dates = dates
                    console.log(this.model);
                    await this.createTourRoute(this.model)
                }
                await this.getList()
            } catch(e) {
                console.error(e);
            } finally{
                this.dialog = false
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tour_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    div {
        width: 400px;
    }
}
.table_actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}
</style>