<template>
    <div>
        <v-data-table
            :headers="busHeaders"
            :items="busItems.results"
            :loading="loading"
            disable-sort
            :options.sync="options"
            :server-items-length="busItems.count"
        >
            <template v-slot:[`item.bus_type`]="{ item }">
                {{item.bus_type=='sitting' ? 'Сидячий' : 'Спальный'}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)' :eyeEdit='true'/>
            </template>
        </v-data-table>
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' @onSubmit='onSubmit' :hideButton='Boolean(editId)'>
            <v-form
                ref="form"
                class="bus_form"
                lazy-validation
            >
                <v-select
                    v-model="busType"
                    :items='busTypes'
                    label="Тип автобуса"
                    class="mb-4"
                    height="44"
                    hide-details
                    outlined
                    dense
                    :rules="[$rules.required]"
                    :readonly='Boolean(editId)'
                    @change="changeBusType(busType)"
                >
                </v-select>
                <v-text-field
                    v-model="busNumber"
                    label="Номер автобуса"
                    height="44"
                    hide-details
                    outlined
                    class="mb-4"
                    dense
                    :rules="[$rules.required]"
                    :readonly='Boolean(editId)'
                >
                </v-text-field>
                <div v-if="busType==='sitting'">
                    <input-content
                        :items="inputItems"
                        :model="model"
                    />
                    <bus-model />
                </div>
                <div v-else>
                    <v-select
                        v-if="Boolean(editId)"
                        v-model="editSeatCount"
                        :items='sleepingOptions'
                        label="Кол-во мест"
                        class="mb-4"
                        height="44"
                        hide-details
                        outlined
                        dense
                        :rules="[$rules.required]"
                        :readonly='Boolean(editId)'
                    >
                    </v-select>
                    <v-select
                        v-else
                        v-model="model.seatCount"
                        :items='sleepingOptions'
                        label="Кол-во мест"
                        class="mb-4"
                        height="44"
                        hide-details
                        outlined
                        dense
                        :rules="[$rules.required]"
                        :readonly='Boolean(editId)'
                    >
                    </v-select>
                    <bus-model ref="slepping" :isSleeping="true" />
                    <div class="mb-2"></div>
                </div>
            </v-form>
        </vue-dialog>
        <vue-delete-dialog :model='deleteDialog' @onClick='deleteHandler' @closeDialog='closeDelete' :text='"Вы уверены что хотите удалить автобус?"'/>
    </div>
</template>

<script>
import BusModel from '@/components/buses/busModel.vue'
import VueAddButton from '@/components/ui/vueAddButton.vue'
import VueTableActions from '@/components/ui/vueTableActions.vue'
import VueDialog from '@/components/ui/vueDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import vueDeleteDialog from '@/components/ui/vueDeleteDialog.vue'
import {mapActions, mapMutations, mapGetters} from 'vuex'
import {toursService} from '@/services/tours.js'
import pagination from "@/utils/pagination"
export default {
    mixins: [pagination],
    components: {
        BusModel,
        VueAddButton,
        VueTableActions,
        VueDialog,
        vueDeleteDialog,
        InputContent
    },
    data: () => ({
        editSeatCount: 32,
        busModel: null,
        loading: false,
        editBus: [],
        editId: null,
        deleteId: null,
        deleteDialog: false,
        busNumber: '',
        busType: 'sitting',
        sleepingModel: {
            seatCount: 32
        },
        sleepingOptions: [32, 36],
        busTypes: [
            {text: 'Сидячий', value: 'sitting'},
            {text: 'Спальный', value: 'sleeping'}
        ],
        dialogOptions: {
            width: '1000',
            title: 'Автобус',
            button: 'Сохранить'
        },
        inputItems: [
            {
                type: 'text',
                label: 'Кол-во мест',
                rules: 'required',
                model: 'seatCount',
                inputType: 'number',
                cols: 6
            },
            {
                type: 'text',
                label: 'Мест сверху',
                rules: 'required',
                model: 'top',
                inputType: 'number',
                cols: 6
            },
            {
                type: 'text',
                label: 'Мест снизу',
                rules: 'required',
                model: 'bottom',
                inputType: 'number',
                cols: 6
            },
            {
                type: 'checkbox',
                label: 'Задний ряд',
                rules: 'required',
                model: 'is_back',
                inputType: 'number',
                cols: 6
            }
        ],
        dialog: false,
        busItems: [],
        busHeaders: [
            { text: 'Автобус', value: 'number' },
            { text: 'Тип', value: 'bus_type' },
            { text: '', value: 'actions' },
        ],
    }),
    computed: {
        ...mapGetters({
            vuexBusModel: 'busModel/getModel',
            postBusModel: 'busModel/postBusModel'
        }),
        model: {
            get() { return this.vuexBusModel },
            set(val) { this.setModel(val)}
        },
    },
    watch: {
        model: {
            handler() { this.setBusModel() },
            deep: true
        },
        dialog(){
            if(this.model.seatCount > 0) {
                this.inputItems = [
                    {
                        type: 'text',
                        label: 'Кол-во мест',
                        rules: 'required',
                        model: 'seatCount',
                        inputType: 'number',
                        cols: 6,
                        readonly: true, 
                    },
                ]
            }
            else {
                this.inputItems = [
                    {
                        type: 'text',
                        label: 'Кол-во мест',
                        rules: 'required',
                        model: 'seatCount',
                        inputType: 'number',
                        cols: 6
                    },
                    {
                        type: 'text',
                        label: 'Мест сверху',
                        rules: 'required',
                        model: 'top',
                        inputType: 'number',
                        cols: 6
                    },
                    {
                        type: 'text',
                        label: 'Мест снизу',
                        rules: 'required',
                        model: 'bottom',
                        inputType: 'number',
                        cols: 6
                    },
                    {
                        type: 'checkbox',
                        label: 'Задний ряд',
                        rules: 'required',
                        model: 'is_back',
                        inputType: 'number',
                        cols: 6
                    }
                ]
            }
        }
    },
    methods: {
        ...mapMutations({
            setSleepingModel: 'busModel/SET_SLEEPING_MODEL',
            setModel: 'busModel/SET_MODEL',
            setBusModel: 'busModel/SET_BUS_MODEL',
            setBackSeat: 'busModel/SET_BACK_SEAT',
            setBusType: 'busModel/SET_BUS_TYPE',
            translateFromBack: 'busModel/TRANSFORM_BUS_MODEL_FROM_BACK'
        }),
        ...mapActions({
            createBus: 'tours/createBus',
            getBuses: 'tours/getBuses',
        }),
        changeBusType(type) {
            if (type=='sleeping') {
                this.model = {
                    seatCount: 32
                }
            } else {
                this.model = {}
            }
            this.setBusType(type)
        },
        changeBusModel(model) {
            this.model.top = model.top
            this.model.bottom = model.bottom
            this.model.seatCount = model.seatCount
            this.model.is_back = model.is_back
        },
        async getList() {
            this.loading = true
            let params = {
                    page: this.options.page, 
                    size: this.options.itemsPerPage
                }
            this.busItems = (await this.getBuses(params))
            this.loading = false
        },
        onAdd() {
            this.busModel = null
            this.busType = 'sitting',
            this.busNumber = ''
            this.model = {}
            this.dialog = true
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
        },
        async onEdit(item) {
            try {
                this.$loading(true)
                this.model = {}
                this.editId = item.id
                this.inputItems.forEach(input => input.readonly = true)
                const res = await toursService.getSingleTourBus(item.id)
                this.editBus = this.$copy(res.bus_seat_places)
                this.busType = res.bus_type
                if (res.bus_type == 'sleeping') {
                    if (res.bus_seat_places.length == 18) {
                        this.editSeatCount = 32
                    } else {
                        this.editSeatCount = 36
                    }
                }
                this.translateFromBack(res)
                this.busNumber = res.number
                this.dialog = true
            } catch (e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        closeDelete() {
            this.deleteDialog = false
            this.deleteId = null
        },
        onDelete(item) {
            this.deleteId = item.id
            this.deleteDialog = true
        },
        closeDialog() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
            this.dialog = false
            this.editId = null
            this.editBus = []
            this.inputItems.forEach(input => input.readonly = false)
        },
        async deleteHandler() {
            try {
                this.$loading(true)
                await toursService.deleteTourBus(this.deleteId)
                await this.getList()
            } catch (e) {
                console.error(e);
            } finally {
                this.closeDelete()
                this.$loading(false)
            }
        },
        async onSubmit() {
            if (this.$refs.form) {
                if (!this.$refs.form.validate()) return
            }
            try {
                this.$loading(true)
                // let test = false
                // if (test) {
                //     // const copy = JSON.parse(JSON.stringify(this.postBusModel))
                //     // const maxColumns = copy.map(el => el.column).sort((a, b) => b - a)[0] 
                //     // const minRow = copy.map(el => el.row).sort((a, b) => a - b)[0]
                //     // const maxRow = copy.map(el => el.row).sort((a, b) => b - a)[0]
                //     // const bus = []
                //     // const itter = maxColumns
                //     // for (let i = minRow - 1; i < itter; i++) {
                //     //     bus.push([])
                //     //     for (let j = 0; j < maxRow; j++) {
                //     //         bus[i].push({seat_place_type: 'default', column: i+1, row: j+1})
                //     //     }
                //     // }
                //     // const testBus = [].concat.apply([], bus);
                //     // console.log(bus);

                //     this.postBusModel.forEach(el => {
                //         this.editBus.forEach(item => {
                //             if (el.name == item.name) {
                //                 el.id = item.id
                //             }
                //         })
                //     })

                //     const arr1 = this.postBusModel.filter(el => el.seat_place_type == 'default')
                //     // const arr1 = this.postBusModel
                //     console.table(arr1);

                //     const changedSeats = []
                //     const patchPromise = []
                //     arr1.forEach(el => {
                //         this.editBus.forEach(item => {
                //             if (el.name == item.name) {
                //                 if (el.column == item.column && el.row == item.row) {
                //                 } else {
                //                     changedSeats.push(el)
                //                 }
                //             }
                //         })
                //     });
                //     changedSeats.forEach(el => {
                //         // patchPromise.push( toursService.patchBusSeatPlace(el) )
                //     })
                //     console.log('PATCH SEATS');
                //     console.table(patchPromise);



                //     const deleteSeats = []
                //     const deletePromise = []
                //     this.editBus.filter(item => item.name != 'empty').forEach(item => {
                //         if (!arr1.map(el => String(el.name)).includes(item.name)) {
                //             deleteSeats.push(item)
                //         }
                //     })
                //     deleteSeats.forEach(el => {
                //         // deletePromise.push( toursService.deleteBusSeatPlace(el) )
                //     })
                //     console.log('DELETE SEATS');
                //     console.table(deleteSeats);

                //     const createSeats = []
                //     const createPromise = []
                //     arr1.filter(item => item.name != 'empty').forEach(item => {
                //         if (!this.editBus.map(el => String(el.name)).includes(String(item.name))) {
                //             createSeats.push([{...item, bus: this.editId}])
                //         }
                //     })
                //     createSeats.forEach(el => {
                //         // createPromise.push( toursService.postSeatPlace(el) )
                //     })
                //     console.log('CREATE SEATS');
                //     console.table(createSeats);

                //     const doors = this.editBus.filter(el => el.seat_place_type == 'door')
                //     doors.forEach(el => {
                //         // deletePromise.push( toursService.deleteBusSeatPlace(el) )
                //     })
                //     console.log('DELETE DOORS');
                //     console.table(doors);

                //     const newDoors = this.postBusModel.filter(el => el.seat_place_type == 'door').map(el => {
                //         return [{...el, bus: this.editId}]
                //     })
                //     newDoors.forEach(el => {
                //         // createPromise.push( toursService.postSeatPlace(el) )
                //     })
                //     console.log('CREATE DOORS');
                //     console.table(newDoors);
                //     return
                // }
                
                const data = {
                    number: this.busNumber,
                    bus_type: this.busType,
                    is_layout: true,
                    bus_seat_places: this.postBusModel
                }
                await this.createBus(data)
                await this.getList()
            } catch(e) {
                console.error(e);
            } finally {
                this.dialog = false
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.bus_form {
    max-width: 500px;
}
</style>