<template>
  <div>
    <div v-if="busModel.length != 2">
      <div v-if="showBus" :class="{ readonly: busModel.length && busModel[0].id && !selectable }" class="bus_wrapper">
        <div class="bus_model">
          <div class="rows" :key="refreshhKey">
            <div v-for="(seat, index) in busModel" :key="index" class="seat" :style="{ gridRow: seat.row, gridColumn: seat.column }">
              <v-menu absolute offset-y style="max-width: 600px" :disabled="selectable">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="seat" @click="selectSeat(seat)"
                    :class="[
                      { preventMenu: seat.seat_place_type === 'empty' || seat.seat_place_type === 'wheel' || isSleeping || selectable, },
                      { selectable: selectable },
                      seat.class,
                      { redSeat: seat.is_blocked_sp }
                    ]"
                    :style="[ seat.seat_place_type === 'empty' ? { pointerEvents: 'none !important' } : {} ]"
                  >
                    <p v-if="seat.seat_place_type === 'default'" :class="{ takenSeatNumber: seat.is_free === false }">
                      {{ typeof seat.name == "number" ? seat.name : seat.name.replace("-back", "") }}
                    </p>
                    <img v-if="seat.seat_place_type === 'wheel'" width="33" src="@/assets/images/bus/wheel.svg" alt=""/>
                    <img v-else-if="seat.seat_place_type === 'door'" width="33" src="@/assets/images/bus/door.svg" alt=""/>
                    <svg v-else-if="seat.is_blocked_sp" width="33" height="36" viewBox="0 0 33 36" fill="white" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#FF0000"/>
                      <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#FF0000"/>
                      <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill=" white" stroke="#FF0000"/>
                      <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#FF0000"/>
                    </svg>
                    <div v-else-if="seat.seat_place_type === 'hidden' || seat.seat_place_type === 'empty'" class="empty_seat"></div>
                    <svg v-else-if="seat.is_free === false || seat.is_blocked_sp === true" width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#DDE5EB"/>
                      <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#DDE5EB"/>
                      <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill="white" stroke="#DDE5EB"/>
                      <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#DDE5EB"/>
                    </svg>
                    <svg v-else-if="seat.seat_place_type === 'default'" width="33" height="36" viewBox="0 0 33 36" fill="white" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#9CAEBC"/>
                      <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#9CAEBC"/>
                      <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill=" white" stroke="#9CAEBC"/>
                      <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#9CAEBC"/>
                    </svg>
                  </div>
                </template>
                <div class="menu_wrapper">
                  <div v-for="(option, idx) in options" :key="idx" @click="changeSeat(seat, option.value)">
                    {{ option.text }}
                  </div>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="seat_statuses">
          <div>
            <img src="@/assets/images/bus/sit.svg" alt="" />
            <p>Доступно</p>
          </div>
          <div>
            <svg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#DDE5EB"/>
              <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#DDE5EB"/>
              <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill="white" stroke="#DDE5EB"/>
              <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#DDE5EB"/>
            </svg>
            <p>Не доступно</p>
          </div>
          <div v-if="selectable">
            <svg width="33" height="36" viewBox="0 0 33 36" fill=" #52B1BA" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="white"/>
              <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill=" #52B1BA" stroke="white"/>
              <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill=" #52B1BA" stroke="white"/>
              <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="#52B1BA" stroke="white"/>
            </svg>
            <p>Выбрано</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="busModel.length == 2">
      <div
        v-for="(item, subindex) in busModel"
        :key="subindex"
        :class="{ readonly: item.length && item[0].id && !selectable }"
        class="bus_wrapper"
      >
        <div class="bus_model">
          <div class="rows" :key="refreshhKey">
            <div v-for="(seat, index) in item" :key="index" class="seat" :style="{ gridRow: seat.row, gridColumn: seat.column }">
              <v-menu absolute offset-y style="max-width: 600px" :disabled="selectable">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="seat" @click="selectSeat(seat)"
                    :class="[
                      { preventMenu: seat.seat_place_type === 'empty' || seat.seat_place_type === 'wheel' || isSleeping || selectable, },
                      { selectable: selectable },
                      seat.class,
                      { redSeat: seat.is_blocked_sp }
                    ]"
                    :style="[ seat.seat_place_type === 'empty' ? { pointerEvents: 'none !important' } : {} ]"
                  >
                    <p v-if="seat.seat_place_type === 'default'" :class="{ takenSeatNumber: seat.is_free === false }">
                      {{ typeof seat.name == "number" ? seat.name : seat.name.replace("-back", "") }}
                    </p>
                    <img v-if="seat.seat_place_type === 'wheel'" width="33" src="@/assets/images/bus/wheel.svg" alt=""/>
                    <img v-else-if="seat.seat_place_type === 'door'" width="33" src="@/assets/images/bus/door.svg" alt=""/>
                    <svg v-else-if="seat.is_blocked_sp" width="33" height="36" viewBox="0 0 33 36" fill="white" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#FF0000"/>
                      <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#FF0000"/>
                      <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill=" white" stroke="#FF0000"/>
                      <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#FF0000"/>
                    </svg>
                    <div v-else-if="seat.seat_place_type === 'hidden' || seat.seat_place_type === 'empty'" class="empty_seat"></div>
                    <svg v-else-if="seat.is_free === false" width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#DDE5EB"/>
                      <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#DDE5EB"/>
                      <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill="white" stroke="#DDE5EB"/>
                      <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#DDE5EB"/>
                    </svg>
                    <svg v-else-if="seat.seat_place_type === 'default'" width="33" height="36" viewBox="0 0 33 36" fill="white" xmlns="http://www.w3.org/2000/svg">
                      <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#9CAEBC"/>
                      <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#9CAEBC"/>
                      <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill=" white" stroke="#9CAEBC"/>
                      <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#9CAEBC"/>
                    </svg>
                  </div>
                </template>
                <div class="menu_wrapper">
                  <div v-for="(option, idx) in options" :key="idx" @click="changeSeat(seat, option.value)">
                    {{ option.text }}
                  </div>
                </div>
              </v-menu>
            </div>
          </div>
        </div>
        <div class="seat_statuses">
          <div>
            <img src="@/assets/images/bus/sit.svg" alt="" />
            <p>Доступно</p>
          </div>
          <div>
            <svg width="33" height="36" viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="#DDE5EB"/>
              <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill="white" stroke="#DDE5EB"/>
              <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill="white" stroke="#DDE5EB"/>
              <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="white" stroke="#DDE5EB"/>
            </svg>
            <p>Не доступно</p>
          </div>
          <div v-if="selectable">
            <svg width="33" height="36" viewBox="0 0 33 36" fill=" #52B1BA" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.0235 4.81262C23.1294 0.605129 6 2.36524 6 2.36524V33.7372C6 33.7372 19.4588 34.7887 26.8 32.6854C34.1412 30.5821 32.9176 9.02012 28.0235 4.81262Z" stroke="white"/>
              <path d="M20 29.2021C21 29.6569 22 30.65 22 31.9306C22 33.2112 21 34.2043 20 34.659C19 35.1137 7.5 35.1137 6 34.659C4.5 34.2043 3 33.4375 3 31.9306C3 30.4237 4 29.2022 6 29.2021C8 29.2021 19 28.7474 20 29.2021Z" fill=" #52B1BA" stroke="white"/>
              <path d="M20 6.79785C21 6.34306 22 5.35001 22 4.06942C22 2.78883 21 1.79565 20 1.34099C19 0.886323 7.5 0.886323 6 1.34099C4.5 1.79565 3 2.56255 3 4.06942C3 5.57629 4 6.79777 6 6.79785C8 6.79793 19 7.25265 20 6.79785Z" fill=" #52B1BA" stroke="white"/>
              <path d="M1.35681 8.01688C1.83259 5.52134 3.4137 3.7745 5.63801 4.02364C7.34103 4.21439 8.49218 5.02231 8.96782 7.01857C9.20271 8.00437 8.0135 13.0072 8.0164 17.9996C8.01937 23.117 9.20858 28.2233 8.96782 28.9814C8.49218 30.4791 7.34103 31.7856 5.63801 31.9764C3.4137 32.2255 1.83254 30.4791 1.35681 27.9831C0.881085 25.4871 0.881039 10.5124 1.35681 8.01688Z" fill="#52B1BA" stroke="white"/>
            </svg>
            <p>Выбрано</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    limit: Number,
    isSleeping: Boolean,
    selectable: Boolean,
    popupOpen: {
      type: Boolean,
      default: false,
    },
    isBlockOption: {
      type: String, 
      default: "", 
    }
  },
  data: () => ({
    blockedSeats: [],
    clickedSeats: [],
    refreshhKey: 0,
    options: [
      { text: "Сиденье", value: "default" },
      { text: "Дверь", value: "door" },
      { text: "Удалить", value: "hidden" },
    ],
  }),
  computed: {
    ...mapGetters({
      busModel: "busModel/getBusModel",
      model: "busModel/getModel",
    }),
    showBus() {
      if (!this.isSleeping) {
        if (Object.keys(this.model).length >= 3) {
          if (!this.model.seatCount) {
            return false;
          }
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
  },
  watch: {
    busModel() {
      console.log(this.busModel);
    },
    isBlockOption(){
      this.blockedSeats = []
      this.busModel.forEach(e => {
        e.class = ''
      })
      this.refreshhKey++;
    },
  },
  methods: {
    ...mapMutations({
      changeSeatOption: "busModel/changeSeat",
    }),
    getClickedSeats() {
      return this.clickedSeats;
    },
    selectSeat(seat) {
      if (seat.name == "wheel" || seat.seat_place_type == "wheel" || seat.seat_place_type == "door") return;
      if(this.isBlockOption) {
        if(this.isBlockOption == 'block' && seat.is_blocked_sp) return
        if(this.isBlockOption == 'unblock' && !seat.is_blocked_sp) return 
        if(!seat.is_free) return
         if (seat.class === "selectedSeat") {
            seat.class = "";
            this.blockedSeats = this.blockedSeats.filter(
              (el) => el.name != seat.name
            );
          } else {
            seat.class = "selectedSeat";
            this.refreshhKey++;
            this.blockedSeats.push(seat);
            if (this.limit < this.blockedSeats.length) {
              this.blockedSeats[0].class = "";
              this.blockedSeats.shift();
            }
          }
          this.refreshhKey++;
          return
      }
      if (this.popupOpen && (!seat.is_free  && !seat.is_blocked_sp)) {
        this.$emit("openPopup", seat);
        return;
      }
      if(this.popupOpen) return
      if (!seat.is_free) return;
      if (!this.selectable) return;
      if (seat.class === "selectedSeat") {
        seat.class = "";
        this.clickedSeats = this.clickedSeats.filter(
          (el) => el.name != seat.name
        );
      } else {
        seat.class = "selectedSeat";
        this.refreshhKey++;
        this.clickedSeats.push(seat);
        if (this.limit < this.clickedSeats.length) {
          this.clickedSeats[0].class = "";
          this.clickedSeats.shift();
        }
      }
      this.refreshhKey++;
    },
    changeSeat(seat, value) {
      this.changeSeatOption({ seat, value });
      this.refreshhKey++;
    },
  },
};
</script>

<style lang="scss" scoped>
.bus_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.seat_statuses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 30px;
  div {
    display: grid;
    grid-template-columns: 33px 1fr;
    align-items: center;
    grid-column-gap: 12px;
    margin-bottom: 10px;
    p {
      font-size: 16px;
      line-height: 19px;
      color: #021011;
      margin-bottom: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.bus_model {
  display: inline-block;
  padding: 16px 20px;
  border: 1px solid #cfd7e3;
  border-radius: 5px;
}
.rows {
  display: grid;
  grid-column-gap: 16px;
  align-items: center;
  justify-content: center;
  grid-gap: 12px;
}
.empty_seat {
  width: 33px;
  height: 36px;
}

.seat {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-bottom: 0;
    left: 10px;
    position: absolute;
    font-family: "Gilroy", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #021011;
    text-align: center;
  }
}
.menu_wrapper {
  background: #fff;
  div {
    cursor: pointer;
    transition: 0.2s;
    padding: 8px;
    &:hover {
      background: #52b1ba;
    }
  }
}
.redSeat{
  p {
    color: #fff !important;
  }
  svg {
    path {
      fill: red !important;
      stroke: #fff !important;
    }
  }
}
.selectedSeat {
  p {
    color: #fff !important;
  }
  svg {
    path {
      fill: #52b1ba !important;
      stroke: #fff !important;
    }
  }
}
.preventMenu {
  pointer-events: none;
}
.selectable {
  pointer-events: unset !important;
}
.takenSeatNumber {
  opacity: 0.5;
}
.readonly {
  pointer-events: none;
}
</style>
