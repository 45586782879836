<template>
  <div class="vue_breadcrumbs">
    <router-link
      v-for="(route, index) in breadCrumbs"
      :key="index"
      :to="{ path: route.to, query: route.query }"
      class="link_item"
      :disabled="route.disabled"
    >
      {{ route.text }}
      <img src="@/assets/images/ui/chevron-right.svg" alt="" />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    breadCrumbs: Array,
  },
};
</script>

<style lang="scss" scoped>
.vue_breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .link_item {
    font-family: "Gilroy", sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #b9b9b9;
    margin-bottom: 0;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    img {
      margin: 0 12px 2px;
    }
    &:last-of-type {
      color: #52b1ba;
      cursor: default;
      img {
        display: none;
      }
    }
  }
  .router-link-exact-active {
    color: #52b1ba;
    cursor: default;
    img {
      display: none;
    }
  }
}
</style>
