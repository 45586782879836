<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <div class="tab_navigation">
            <v-tabs v-model="tab">
                <v-tab>Маршруты</v-tab>
                <v-tab>Автобусы</v-tab>
            </v-tabs>
            <vue-add-button @onClick='onAdd'>
                {{tab==0 ? 'Добавить маршрут' : 'Добавить автобус'}} 
            </vue-add-button>
        </div>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <tour-route ref="tourRoute"/>
            </v-tab-item>
            <v-tab-item>
                <tour-bus ref="tourBus"/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import VueAddButton from '@/components/ui/vueAddButton.vue'
import TourBus from '@/components/administration/tours/tourBus.vue'
import TourRoute from '@/components/administration/tours/tourRoute.vue'
export default {
    components: {
        VueBreadcrumbs,
        VueAddButton,
        TourBus,
        TourRoute
    },
    data: () => ({
        tab: 0,
        breadCrumbs: [
            {
                text: 'Заведение блоков',
                to: '/administration/block'
            },
            {
                text: 'Экскурсии',
                to: '/administration/block/tours',
            }
        ]
    }),
    watch: {
        tab(val) {
            if (val===0) {
                if (this.$refs.tourRoute) {
                    this.$refs.tourRoute.getTourRoutes()
                }
            } else {
                if (this.$refs.tourBus) {
                    this.$refs.tourBus.getList()
                } 
            }
        }
    },
    methods: {
        onAdd() {
            if (this.tab==0) {
                this.$refs.tourRoute.onAdd()
            } else {
                this.$refs.tourBus.onAdd()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.tab_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
</style>